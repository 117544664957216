import React from "react";
import {
	Box,
	Button,
	Flex,
	Text,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import { TextInput } from "~/components/TextInput";

export type ContactField = "name" | "email" | "phone" | "instagram" | "city" | "state";

interface IProps {
	isLoading: boolean;
	onSubmit: () => void;
	onHandleChange: (field: ContactField, value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const ContactForm: React.FC<IProps> = (props) => {
	const {
		isLoading,
		onSubmit,
		onHandleChange,
	} = props;
	const sectionStrings = strings.sections.contactForm;

	return (
		<Flex
			bgColor="#D8D4C9"
			justifyContent="center"
			alignItems="center"
			flexDir="column"
		>
			<Box
				my={30}
				pt={{ base: 4, lg: 10 }}
				w={{ base: 342, lg: 956 }}
				pb={{ base: 0, lg: 10 }}
				textAlign="center"
			>
				<Text
					fontWeight={800}
					fontSize={{ base: 20, lg: 50 }}
					width={{ base: 299, lg: 788 }}
					textAlign="center"
					margin="0 auto"
				>
					{sectionStrings.subscribe}
					<Text as="strong" fontWeight={400}>{sectionStrings.firstText}</Text>
					<Text as="strong" color="primary.400">{sectionStrings.businessPlan}</Text>
					<Text as="strong" fontWeight={400}>{sectionStrings.finalText}</Text>
				</Text>
				<Flex
					w="100%"
					flexDir="column"
					mt={{ base: 3, lg: 10 }}
					gap={2}
				>
					<TextInput
						inputProps={{
							placeholder: sectionStrings.placeholders.name,
						}}
						type="text"
						isDisabled={isLoading}
						onChange={(e) => onHandleChange("name", e)}
					/>
					<TextInput
						inputProps={{
							placeholder: sectionStrings.placeholders.email,
						}}
						type="text"
						isDisabled={isLoading}
						onChange={(e) => onHandleChange("email", e)}
					/>
					<TextInput
						inputProps={{
							placeholder: sectionStrings.placeholders.phone,
						}}
						type="text"
						mask="(99) 99999-9999"
						isDisabled={isLoading}
						onChange={(e) => onHandleChange("phone", e)}
					/>
					<TextInput
						inputProps={{
							placeholder: sectionStrings.placeholders.instagram,
						}}
						type="text"
						isDisabled={isLoading}
						onChange={(e) => onHandleChange("instagram", e)}
					/>
					<TextInput
						inputProps={{
							placeholder: sectionStrings.placeholders.city,
						}}
						type="text"
						isDisabled={isLoading}
						onChange={(e) => onHandleChange("city", e)}
					/>
					<TextInput
						inputProps={{
							placeholder: sectionStrings.placeholders.state,
						}}
						type="text"
						isDisabled={isLoading}
						onChange={(e) => onHandleChange("state", e)}
					/>
					<Button
						onClick={() => onSubmit()}
						py={{ base: 6, lg: 12 }}
						px={10}
						fontSize={{ base: 18, lg: 40 }}
						width={{ base: 293, lg: 655 }}
						margin="0 auto"
						mt={{ base: 4, lg: 45 }}
						textTransform={{ base: "uppercase", lg: "none" }}
					>
						{strings.common.buttons.downloadBusinessPlan1}
					</Button>
				</Flex>
			</Box>
		</Flex>
	);
};
