import React from "react";
import {
	Flex,
	Image,
	Text,
} from "@chakra-ui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { CardDepoiment } from "~/components/CardDepoiment";
import strings from "~/resources/strings";

export const Depoiment: React.FC = () => {
	const sectionStrings = strings.sections.depoiment;

	const depoiments = [
		{
			image: "img/rebecca.png",
			text: `É o meio de fazer grana extra e inovar no look, sem gastar muito ou ocupar espaço no closet.
			Alugo peças casuais e mais arrumadas em meu armário compartilhado!`,
			client: "Rebecca Brelaz",
		},
		{
			image: "img/leticia.png",
			text: `O LOC é revolucionário e muito útil! Uso a alguns anos e sempre indico para as minhas amigas.
			Recentemente eu viajei pra lugar de neve para esquiar e consegui economizar muito na roupa alugando por lá! Seria incrível se todo mundo abrisse a mente e começasse a usar mais, sinto que tenho o armário de várias amigas disponíveis pra eu usar as roupas quando eu quiser 💖`,
			client: "Letícia Imbassahy",
		},
		{
			image: "img/ingra.png",
			text: `É incrível a possibilidade de acessar tantas peças em alguns cliques e ainda ganhar uma renda extra com as minhas!
			Tenho armário cadastrado no app desde o início! Sempre fui apaixonada por sustentabilidade e quando vi o aplicativo pensei “meu deus como não tinham criado isso antes?”`,
			client: "Ingra Deonísio",
		},
	];

	return (
		<Flex
			bg="pink.300"
			direction="column"
			px={5}
		>
			<Flex
				my={{
					base: 4,
					md:7 ,
				}}
				w={{
					md:"95%",
					lg: "90%",
					xl: "80%",
				}}
				justifyContent={{
					md:"right",
					base: "center",
				}}
			>
				<Image
					mr={3}
					width={{
						base:25,
						md:"unset",
					}}
					src="img/heart-icon.svg"
					alt={sectionStrings.title} />
				<Text
					fontSize={{
						base: 30,
						md: 50,
					}}
					fontWeight="700"
					color="white"
				>
					{sectionStrings.title}
				</Text>
			</Flex>
			<Flex
				w="100%"
				display={{base:"none", md:"flex"}}
				justifyContent="center"
				gap={4}
				mb={5}
			>
				{depoiments.map((item, index) => (
					<CardDepoiment
						image={item.image}
						text={item.text}
						client={item.client}
						key={index} />),
				)}
			</Flex>

			<Flex display={{base:"block", md:"none"}}>
				<Carousel
					autoPlay
					infiniteLoop
					showArrows={false}
					showStatus={false}
					swipeable
					emulateTouch
					showThumbs={false}
					width="100%"
				>
					{depoiments.map((item, index) => (
						<Flex
							pb={20}
							pt={5}
							bg="pink.300"
							key={index}
							direction="column"
							w="100%	"
						>
							<CardDepoiment
								isSlide
								image={item.image}
								text={item.text}
								client={item.client}
							/>
						</Flex>
					),
					)}
				</Carousel>
			</Flex>
		</Flex>
	);
};

