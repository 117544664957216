import React from "react";
import {
	Box,
	Image,
	Button,
	Text,
	Flex,
	Divider,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import { HeadFrame } from "./HeadFrame";

const sectionStrings = strings.sections.initial;

const scrollToContact = () => {
	window.scrollTo({
		top: 9000,
		behavior: "smooth",
	});
};

export const Initial: React.FC = () => (
	<Box
		pt={14}
		pb={{ base: 9, lg: 7 }}
	>
		<HeadFrame />
		<Divider
			width={{ base: 292, lg: 954 }}
			border="1px solid"
			borderColor="secondary.200"
			margin={{ base: "0 auto" }}
			mt={8}
		/>
		<Flex
			pt={12}
			flexDirection={{ base: "column", lg: "row" }}
			alignItems="center"
			justifyContent={{ base: "initial", lg: "center" }}
			gap={{ base: 0, lg: 187 }}
		>
			<Flex
				flexDirection="column"
				alignItems="center"
			>
				<Text
					fontSize={{ base: 30, lg: 40 }}
					fontWeight={500}
				>
					{sectionStrings.whyLoc}
				</Text>
				<Image
					alt="Logo"
					src="img/logo.svg"
					width={{ base: 250, lg: 413 }}
					pb={3}
				/>
				<Text
					fontSize={{ base: 30, lg: 40 }}
					color="black"
					fontWeight={500}
				>
					{sectionStrings.interrogation}
				</Text>
			</Flex>
			<Flex
				pt={7}
				flexDirection="column"
				gap={5}
				width={{ base: 260, lg: 426 }}
			>
				<Text
					fontSize={{ base: 20, lg: 40 }}
					textAlign={{ base: "center", lg: "start" }}
				>
					{sectionStrings.be}
					<Text
						as="strong"
						fontSize={{ base: 20, lg: 40 }}
						bgColor="green.500"
						pl={1}
					>
						{sectionStrings.franchised}
					</Text>
					{sectionStrings.of}
					<Text
						as="strong"
						fontSize={{ base: 20, lg: 40 }}
					>
						{sectionStrings.firstAppOf}
					</Text>
				</Text>

				<Text
					fontSize={{ base: 20, lg: 40 }}
					textAlign={{ base: "center", lg: "start" }}
				>
					{sectionStrings.moreOf}
					<Text
						as="strong"
						fontSize={{ base: 20, lg: 40 }}
						color="pink.400"
					>
						{sectionStrings.numberOfPieces}
					</Text>
					{sectionStrings.forYou}
					<Text
						as="strong"
						fontSize={{ base: 20, lg: 40 }}
					>
						{sectionStrings.useAndReturn}
					</Text>
				</Text>
			</Flex>
		</Flex>
		<Flex
			justifyContent="center"
			pt={{ base: 5, lg: 28 }}
		>
			<Button
				onClick={scrollToContact}
				variant="outline"
				textTransform="uppercase"
				fontWeight={800}
				w={{ base: 293, lg: 492 }}
				fontSize={{ base: 20, lg: 30 }}
				py={{ base: 6, lg: 10 }}
			>
				{strings.common.buttons.iWantKnowMore}
			</Button>
		</Flex>
	</Box>
);
