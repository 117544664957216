import React from "react";
import {
	Box,
	Flex,
	Heading,
	Text,
} from "@chakra-ui/react";

interface IProps {
	title: string;
	description: string;
}

export const Topic: React.FC<IProps> = (props) => {

	const { title, description } = props;

	return (
		<Box pb={5} maxW={{ base: 283, lg: "100%" }}>
			<Flex pb={{ base: 1, lg: 3 }} justifyContent={{ base: "center", lg: "initial" }}>
				<Heading
					as="h4"
					px={2}
					py={0.5}
					fontSize={{ base: 20, lg: 25 }}
					fontWeight="bold"
					bgColor="green.500"
					color="secondary.800"
					textTransform="uppercase"
					textAlign={{ base: "center", lg: "initial" }}
				>
					{title}
				</Heading>
			</Flex>
			<Text
				fontSize={{ base: 16, lg: 20 }}
				color="secondary.50"
				fontWeight="light"
				textAlign={{ base: "center", lg: "initial" }}
			>
				{description}
			</Text>
		</Box>
	);
};
