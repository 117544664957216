import { makeAutoObservable } from "mobx";
import { LoaderShelf } from "@startapp/mobx-utils";
import { FormShelf } from "@startapp/mobx-utils/src/web";

import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

export default class Store {
	public loader = new LoaderShelf();

	public formShelf = new FormShelf({
		name: "",
		email: "",
		phone: "",
		instagram: "",
		city: "",
		state: "",
	});

	constructor(){
		makeAutoObservable(this);
	}

	public sendContactForFranchise = async() => {
		this.loader.tryStart();
		try{
			const formValues = this.formShelf.getValues();
			if (!formValues.email || !formValues.name || !formValues.phone) {
				return showErrorToast(strings.error.emptyFields);
			}
			await api.sendEmailForFranchise(formValues);
			showSuccessToast(strings.pages.home.contact.success);
		}catch(e){
			onFetchError(e);
		}finally{
			this.loader.end();
		}
	};
}


