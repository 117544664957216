import React from "react";
import {
	Box,
	Button,
	Flex,
	Text,
} from "@chakra-ui/react";
import strings from "~/resources/strings";

const sectionStrings = strings.sections.businessPlan;

const scrollToContact = () => {
	window.scrollTo({
		top: 9000,
		behavior: "smooth",
	});
};

export const FrameCallToAction: React.FC = () => (
	<Box
		pt={10}
		minHeight={{ base: 227, lg: 352 }}
		height={{ lg: 352 }}
		bgImage="radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, #EFEFEF 100%)"
	>
		<Flex
			margin="0 auto"
			maxW="1440px"
			px={{ base: 0, lg: 20 }}
			flexDirection={{ base: "column", lg: "row" }}
			justifyContent={{ base: "center", lg: "space-around" }}
			alignItems="center"
			height="100%"
			flexWrap="wrap"
			gap={{ base: 4, lg: 0 }}
		>
			<Text
				fontSize={{ base: 20, lg: 35 }}
				fontWeight="light"
				textAlign="center"
				maxWidth={{ base: 350, lg: 600 }}
			>
				<Text as="strong" fontWeight={600}>
					{sectionStrings.moreThan}
				</Text>
				{sectionStrings.clothingRental}
				<Text as="strong" fontWeight={600}>
					{sectionStrings.locHasBecome}
				</Text>
				<Text as="br" />
				<Text
					as="strong"
					fontWeight={600}
					color="pink.400"
				>
					{sectionStrings.fashionSocialNetwork}
				</Text>
			</Text>
			<Button
				onClick={scrollToContact}
				variant="outline"
				textTransform="uppercase"
				width={{ base: 293, lg: 492 }}
				fontSize={{ base: 18, lg: 30 }}
				py={{ base: 6, lg: 10 }}
			>
				{strings.common.buttons.downloadBusinessPlan1}
			</Button>
		</Flex>
	</Box>
);
