import React from "react";
import {
	Text,
	Input,
	Flex,
	InputProps,
	FormControl,
	FormControlProps,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";

interface IProps extends Omit<InputProps, "value"> {
	labelText?: string;
	type?: React.HTMLInputTypeAttribute;
	inputProps?: InputProps;
	isLoading?: boolean;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	value?: string | null;
	error?: string | null;
	mask?: string;
	errorText?: string | null;
	formControlProps?: FormControlProps;
}

export const TextInput: React.FC<IProps> = (props) => {
	const {
		labelText,
		type,
		error,
		inputProps,
		value,
		mask,
		onChange,
		isLoading,
		errorText,
		formControlProps,
	} = props;

	return (
		<Flex direction="column">
			<FormControl isInvalid={!!errorText} position="relative" {...formControlProps}>
				<Text
					fontSize={20}
					color="primary.700"
				>
					{labelText}
				</Text>
				<Input
					fontSize={{ base: 14, lg: 25 }}
					height={{ base: "39px", lg: "72px" }}
					isDisabled={isLoading}
					onChange={onChange}
					value={value}
					px={{ base: 3, lg: 7 }}
					bg="transparent"
					type={type}
					error={error}
					as={mask ? InputMask : undefined}
					mask={mask}
					{...inputProps}
				/>
			</FormControl>
		</Flex>
	);
};
