import React from "react";
import {
	Box,
	Heading,
	Flex,
	Text,
	Divider,
} from "@chakra-ui/react";
import strings from "~/resources/strings";

const sectionStrings = strings.sections.franchiseTable.table;

export const Table: React.FC = () => (
	<Box
		margin="0 auto"
		maxWidth={{ base: 339, lg: 762 }}
		minW={{ base: 320, lg: 762 }}
		height={{ base: 521, lg: 552 }}
		borderRadius="36px"
		borderColor="black"
		border={{ base: "1px solid", lg: "2px solid" }}
		px={{ base: 1, lg: 2 }}
		pt={3}
	>
		<Flex py={3} justifyContent="center">
			<Heading
				fontSize={{ base: 24, lg: 40 }}
				color="black"
				textAlign="center"
				textTransform="uppercase"
				width={{ base: 248, lg: 352 }}
			>
				{sectionStrings.title}
			</Heading>
		</Flex>
		<Divider
			width={{ base: 111, lg: 248 }}
			borderColor="secondary.200"
			margin="0 auto"
		/>
		<Flex
			flexDirection="column"
			gap={5}
			px={2}
			pt={{ base: 6, lg: 8 }}
		>
			{sectionStrings.rows.map((item) => (
				<>
					<Flex
						key={item.title}
						justifyContent="space-between"
						width="100%"
					>
						<Heading
							fontSize={{ base: 16, lg: 25 }}
							fontWeight={400}
							width={{ base: 168, lg: "auto" }}
							lineHeight={{ base: "18px" }}
						>
							{item.title}
						</Heading>
						<Text fontSize={{ base: 16, lg: 25 }}>
							{item.value}
						</Text>
					</Flex>
				</>
			))}
		</Flex>
		<Flex flexDirection="column" pt={6}>
			<Flex
				justifyContent="space-between"
				alignItems="center"
				bgColor="green.500"
				px={2}
			>
				<Heading fontSize={{ base: 16, lg: 25 }} fontWeight={600}>
					{sectionStrings.totalFee.title}
				</Heading>
				<Text fontSize={{ base: 16, lg: 25 }} fontWeight={600}>
					{sectionStrings.totalFee.value}
				</Text>
			</Flex>
			<Box px={3} pt={2}>
				<Text
					width={{ base: 253, lg: "auto" }}
					fontSize={{ base: 16, lg: 25 }}
					fontWeight={600}
					lineHeight="18px"
				>
					{sectionStrings.observation}
				</Text>
			</Box>
		</Flex>
	</Box>
);
