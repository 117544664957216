import React from "react";
import strings from "~/resources/strings";
import {
	Image,
	Flex,
	Heading,
	Text,
	Button,
} from "@chakra-ui/react";

const sectionStrings = strings.sections.initial;


export const HeadFrame: React.FC = () => {

	const scrollToContact = () => {
		window.scrollTo({
			top: 9000,
			behavior: "smooth",
		});
	};

	return (
		<>
			<Flex
				flexDirection="column"
				alignItems="center"
			>
				<Image
					alt="Logo"
					src="img/logo.svg"
					width={{ base: 286, lg: 511 }}
					pb={3}
				/>
				<Heading
					as="h1"
					fontSize={{ base: 18, lg: 30 }}
					color="primary.400"
					textTransform="uppercase"
				>
					{sectionStrings.title}
				</Heading>
			</Flex>
			<Flex
				flexDirection="column"
				alignItems="center"
				pt={8}
			>
				<Text
					fontSize={{ base: 18, lg: 40 }}
					color="black"
					textAlign="center"
				>
					{sectionStrings.subtitleInvestiment}
				</Text>
				<Text
					fontSize={{ base: 18, lg: 40 }}
					color="black"
					fontWeight={800}
					textAlign="center"
				>
					{sectionStrings.subtitleModelBusiness}
				</Text>
				<Button
					onClick={scrollToContact}
					mt={{ base: 8, lg: 16 }}
					variant="solid"
					textTransform="uppercase"
					width={{ base: 293, lg: 492 }}
					py={{ base: 6, lg: 10 }}
					fontSize={{ base: 20, lg: 30 }}
				>
					{strings.common.buttons.iWantMyFranchise}
				</Button>
			</Flex>
			<Flex justifyContent="center">
				<Image
					alt={sectionStrings.alt.mobileFrame}
					src="img/mobile-frame-1.png"
					pt={{ base: 4, lg: 10 }}
				/>
			</Flex>
		</>
	);
};
