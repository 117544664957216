import React from "react";
import {
	Box,
	Heading,
} from "@chakra-ui/react";

import { FramePart } from "./FramePart";
import { GridContent } from "./GridContent";
import strings from "~/resources/strings";

const sectionStrings = strings.sections.highlights;

export const Highlights: React.FC = () => (
	<Box overflow="hidden">
		<Box
			bgColor="#D8D4C9"
			width="100%"
			py={{ base: 8, lg: 16 }}
		>
			<Heading
				fontSize={{ base: 20, lg: 40 }}
				textAlign="center"
			>
				{sectionStrings.title}
			</Heading>
			<Box margin="0 auto" maxW="1440px">
				<GridContent />
			</Box>
		</Box>
		<FramePart />
	</Box>
);
