import React from "react";
import {
	Box,
	Heading,
	Text,
	Flex,
	Divider,
	Button,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import { Table } from "./Table";

const sectionStrings = strings.sections.franchiseTable;

const scrollToContact = () => {
	window.scrollTo({
		top: 9000,
		behavior: "smooth",
	});
};

export const FranchiseTable: React.FC = () => (
	<Flex
		w="100%"
		flexDirection="column"
		gap={5}
		alignItems="center"
		justifyContent="center"
		py={{ base: 14, lg: 4 }}
	>
		<Flex
			width="100%"
			justifyContent="center"
			alignItems="center"
			flexWrap="wrap"
			gap={5}
		>
			<Box width={{ base: "100%", lg: "auto" }}>
				<Table />
			</Box>
			<Box width={{ base: "100%", lg: "auto" }}>
				<Box
					margin="0 auto"
					maxWidth={{ base: 339, lg: 486 }}
					minW={{ base: 320, lg: 486 }}
					height={{ base: 309, lg: 552 }}
					border={{ base: "1px", lg: "2px" }}
					borderRadius="36px"
					color="primary.400"
				>
					<Heading
						py={{ base: 3, lg: 5 }}
						margin="0 auto"
						fontSize={{ base: 24, lg: 40 }}
						color="primary.400"
						textTransform="uppercase"
						width={{ base: 156, lg: 300 }}
						textAlign="center"
						fontWeight={800}
					>
						{sectionStrings.locFranchise}
					</Heading>
					<Divider
						width={{ base: 111, lg: 248 }}
						borderColor="secondary.200"
						margin="0 auto"
					/>
					<Text
						margin="0 auto"
						fontSize={{ base: 40, lg: 60 }}
						fontWeight={400}
						color="black"
						textAlign="center"
						textTransform="uppercase"
						width={{ base: 247, lg: 359 }}
						lineHeight={{ base: "43px", lg: "60px" }}
						pt={{ base: 6, lg: 16 }}
					>
						{sectionStrings.youPay}
						<Text as="strong" color="primary.400">
							{sectionStrings.just}
						</Text>
						{sectionStrings.theFranchiseFee}
					</Text>
				</Box>
			</Box>
		</Flex>
		<Button
			onClick={scrollToContact}
			mt={{ base: 0, lg: 8 }}
			variant="solid"
			width={{ base: 293, lg: "89%" }}
			textTransform="uppercase"
			fontSize={{ base: 20, lg: 60 }}
			fontWeight={800}
			py={{ base: 6, lg: 12 }}
		>
			{strings.common.buttons.iWantMyFranchise}
		</Button>
	</Flex >
);
