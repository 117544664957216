import React from "react";
import {
	Box,
	Button,
	Flex,
	Text,
} from "@chakra-ui/react";
import strings from "~/resources/strings";

const sectionStrings = strings.sections.allMoments.text;

const scrollToContact = () => {
	window.scrollTo({
		top: 10000,
		behavior: "smooth",
	});
};

export const AllMoments: React.FC = () => (
	<Box
		bgColor="pink.300"
		width="100%"
		height={{ base: "100vh", lg: "80vh" }}
	>
		<Flex
			direction={{ base: "column-reverse", lg: "row" }}
			height="100%"
		>
			<Flex
				width={{ base: "100%", lg: "50%" }}
				height={{ base: "30%", lg: "100%" }}
				flexDirection="column"
				alignItems={{ base: "center", lg: "end" }}
				justifyContent="center"
				pr={{ base: 0, lg: 12 }}
				pl={{ base: 0, lg: 5 }}
			>
				<Text
					textAlign={{ base: "center", lg: "end" }}
					lineHeight="129%"
					fontSize={{ base: 20, md: 30, lg: 60 }}
					fontWeight={400}
					color="white"
					maxWidth={{ base: 272, md: 500, lg: 530 }}
				>
					{sectionStrings.firstPart}
					<Text
						as="strong"
						color="white"
						fontWeight="800"
					>
						{sectionStrings.strong}
					</Text>
					{sectionStrings.lastPart}
				</Text>
				<Button
					onClick={scrollToContact}
					variant="secondary"
					fontWeight={800}
					width={{ base: 293, lg: 327 }}
					py={{ base: 6, lg: 7 }}
					fontSize={{ base: 20, lg: 30 }}
					mt={5}
				>
					{strings.common.buttons.iWantKnowMore}
				</Button>
			</Flex>
			<Box />
			<Box
				bgImage="url('img/banner-women.png')"
				bgSize="cover"
				bgPosition={{ base: "center", lg: "50% 30%" }}
				width={{ base: "100%", lg: "50%" }}
				height={{ base: "70%", lg: "100%" }}
			/>
		</Flex>
	</Box>
);
