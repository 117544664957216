import React from "react";
import {
	Image,
	Flex,
	Box,
	Text,
} from "@chakra-ui/react";

interface IProps {
	image: string;
	text: string;
	client: string;
	isSlide?: boolean;
}

export const CardDepoiment: React.FC<IProps> = (props) => {
	const {
		image,
		text,
		client,
		isSlide,
	} = props;

	return (
		<Box
			bg="white"
			boxShadow="md"
			mx={isSlide ? "auto" : "unset"}
			maxW={{ base: 280, md: 400 }}
			p={{ base: "20px", md: "30px" }}
			borderRadius={{ base: 18, md: 39 }}
		>
			<Flex
				w="100%"
				justify="center"
				alignItems="center"
				flexDir="column"
			>
				<Box w="100%" mb={21}>
					<Image
						src={image}
						alt="Cliente"
						w={352}
						borderRadius="5%"
					/>
				</Box>
				<Flex
					w="100%"
					ml={5}
					direction="column"
				>
					<Flex
						mb={5}
						direction={isSlide ? "column" : "row"}
					>
						<Box w={30}>
							<Image
								src="img/quotes-icon.svg"
								alt="quotes-icon"
								h="28px"
								minW="25px"
							/>
						</Box>
						<Text
							ml={isSlide ? 0 : 1}
							textAlign="left"
							mt="4px"
							maxW="300px"
							lineHeight="19px"
							fontSize={{ base: 14, md: 16 }}
						>
							{text}
						</Text>
					</Flex>
					<Text
						as="span"
						color="primary"
						textAlign="left"
						fontWeight={700}
						lineHeight="27px"
						ml={{ base: "none", md: 9 }}
						fontSize={{ base: 18, md: 22 }}
					>
						{client}
					</Text>
				</Flex>
			</Flex>
		</Box>
	);
};
