import React from "react";
import strings from "~/resources/strings";
import {
	Grid,
	GridItem,
	Image,
	Heading,
	Text,
} from "@chakra-ui/react";

const sectionStrings = strings.sections.highlights;

export const GridContent: React.FC = () => (
	<Grid
		pt={{ base: 7, lg: 12 }}
		px={{ base: 3, lg: 12 }}
		pb={{ base: 0, lg: 3 }}
		templateColumns="repeat(auto-fit, minmax(150px, 1fr))"
		rowGap={{ base: 8, lg: 0 }}
	>
		{sectionStrings.topics.map((item) => (
			<>
				<GridItem
					key={item.icon}
					display="flex"
					flexDirection="column"
					alignItems="center"
					margin="0 auto"
					px={{ base: 0, lg: 3 }}
					maxW={{ base: 143, lg: 268 }}
				>
					<Image
						alt={item.title}
						src={item.icon}
						width={{ base: 105, lg: 170 }}
					/>
					<Heading
						fontSize={{ base: 16, lg: 25 }}
						textAlign="center"
					>
						{item.title}
					</Heading>
					<Text
						fontSize={{ base: 14, lg: 20 }}
						textAlign="center"
						pt={1}
					>
						{item.description}
					</Text>
				</GridItem>
			</>
		))}
	</Grid>
);
