import React from "react";
import {
	Flex,
	keyframes,
	Link,
} from "@chakra-ui/react";
import Image from "next/image";
import wpp from "../../public/img/wpp.svg";

const pulse = keyframes`
	0% {
		transform: scale(0.9);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 40px rgba(118, 174, 106, 0);
	}

	100% {
		transform: scale(0.9);
		box-shadow: 0 0 0 0 rgba(118, 174, 106, 0);
	}
`;

const animation = `2s ease infinite ${pulse}`;

export const WhatsAppFloatingButton: React.FC = () => {

	const onClickGTagEvent = (eventName: string) => {
		if (typeof gtag !== "undefined") {
			gtag("event", eventName, {
				event_category: "Site Forms",
			});
		}
	};

	return (
		<Flex
			cursor="pointer"
			zIndex={999999}
			position="fixed"
			bottom="62px"
			right="30px"
		>
			<Link
				width="58px"
				height="58px"
				cursor="pointer"
				borderRadius="50%"
				boxShadow="0 0 0 0 rgb(118,174,106, .5)"
				animation={animation}
				href="https://wa.me/+5571981033219"
				onClick={() => onClickGTagEvent("WhatsApp Button Clicked")}
				target="_blank"
				rel="noreferrer"
				_hover={{
					animation: "none",
				}}
			>
				<Image
					src={wpp}
					alt="WhatsApp"
					width="100%"
					height="100%"
				/>
			</Link>
		</Flex>
	);
};
