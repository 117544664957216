import React from "react";
import {
	Box,
	Image,
	Flex,
	Heading,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import { Topic } from "~/components/Topic";
import { FrameCallToAction } from "./FrameCallToAction";

const sectionStrings = strings.sections.businessPlan;

export const BusinessPlan: React.FC = () => (
	<Flex
		flexDirection="column"
		width="100%"
	>
		<Box
			bgImage="linear-gradient(rgba(0, 0, 0, 0.507), rgba(0, 0, 0, 0.507)), url('img/banner-business-plan.svg')"
			bgPosition="center"
			bgRepeat="no-repeat"
			bgSize="cover"
			width="100%"
			height={{ base: "auto", lg: 830 }}
			position="relative"
		>
			<Flex
				pr={{ base: 0, lg: 5 }}
				columnGap={20}
				alignItems="center"
				justifyContent="space-around"
				height="100%"
				maxWidth="1440px"
				margin="0 auto"
				flexDirection={{ base: "column", lg: "row" }}
			>
				<Image
					position="absolute"
					alt={sectionStrings.alt.iphoneFrame}
					src="img/iphone-frame-business.svg"
					width={{ base: 275, lg: 630 }}
					top={{ base: -62, lg: -105 }}
					left={{ base: -1, lg: 0 }}
				/>
				<Flex
					justifyContent="center"
					pl={145}
					pt={85}
					width="100%"
					height={395}
				>
					<Flex
						position={{ base: "initial", lg: "absolute" }}
						right={{ base: 0, lg: "48%" }}
						pr={{ base: 0, lg: 10 }}
						flexDirection="column"
						alignItems={{ base: "initial", lg: "end" }}
						top={{ base: "initial", lg: 256 }}
					>
						<Heading
							zIndex={999}
							fontSize={{ base: 25, lg: 50 }}
							color="white"
							fontWeight={400}
							width={{ base: 133, lg: 235 }}
							textAlign={{ base: "center", lg: "end" }}
						>
							{sectionStrings.title}
							<Heading
								as="strong"
								color="white"
								fontSize={{ base: 25, lg: 50 }}
							>
								{sectionStrings.exist}
							</Heading>
						</Heading>
						<Image
							alt="Logo"
							src="img/logo.svg"
							width={{ base: 133, lg: 211 }}
							zIndex={999}
						/>
					</Flex>
				</Flex>
				<Box width={{ lg: 1335 }}>
					{sectionStrings.topics.map((item) => (
						<Topic
							key={item.title}
							title={item.title}
							description={item.description}
						/>
					))}
				</Box>
			</Flex>
		</Box >
		<FrameCallToAction />
	</Flex >
);
