import React from "react";
import {
	Box,
	Flex,
	Button,
	Heading,
	Image,
} from "@chakra-ui/react";
import strings from "~/resources/strings";

const sectionStrings = strings.sections.highlights;

const scrollToContact = () => {
	window.scrollTo({
		top: 9000,
		behavior: "smooth",
	});
};

export const FramePart: React.FC = () => (
	<Box bg="white">
		<Flex
			position="relative"
			overflow={{ base: "hidden", lg: "initial" }}
			justifyContent={{ base: "flex-start", lg: "center" }}
			flexDirection={{ base: "column", lg: "row" }}
			height={{ base: 465, lg: 367 }}
			bgImage="radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, #EFEFEF 100%)"
		>
			<Flex
				flexDirection="column"
				alignItems="center"
				pt={12}
			>
				<Heading
					fontSize={{ base: 20, lg: 40 }}
					fontWeight={400}
					width={{ base: 161, lg: 322 }}
					textAlign="center"
				>
					{sectionStrings.subtitle}
					<Heading
						as="strong"
						fontSize={{ base: 20, lg: 40 }}
					>
						{sectionStrings.expansion}
					</Heading>
				</Heading>
				<Button
					onClick={scrollToContact}
					mt={6}
					variant="solid"
					width={{ base: 293, lg: 492 }}
					py={{ base: 6, lg: 12 }}
					fontSize={{ base: 20, lg: 30 }}
					textTransform="uppercase"
				>
					{strings.common.buttons.iWantMyFranchise}
				</Button>
			</Flex>
			<Flex
				display={{ base: "flex", lg: "none" }}
				justifyContent="center"
			>
				<Image
					alt={sectionStrings.alt.iphoneFrame}
					src="img/iphone-loc.png"
					position="absolute"
					bottom={-185}
				/>
			</Flex>
			<Flex
				display={{ base: "none", lg: "flex" }}
				justifyContent="center"
				alignItems="center"
			>
				<Image
					alt={sectionStrings.alt.iphoneFrame}
					src="img/iphone-loc.svg"
					mt={125}
					bottom={-240}
					width="400px"
				/>
			</Flex>
		</Flex>
	</Box>
);
