import React from "react";
import { useLocalObservable } from "mobx-react-lite";
import type { NextPage } from "next";
import { Box } from "@chakra-ui/react";
import {
	Initial,
	AllMoments,
	Highlights,
	BusinessPlan,
	Depoiment,
	ContactForm,
	FranchiseTable,
} from "~/components/Sections";
import { PageContainer, MainLayout } from "~/layout";
import Store from "~/stores/HomeStore/store";
import { ContactField } from "~/components/Sections/ContactForm";
import { WhatsAppFloatingButton } from "~/components";
import { NextSeo } from "next-seo";
import strings from "~/resources/strings";

const HomePage: NextPage = () => {
	const store = useLocalObservable(() => new Store());
	const seoStrings = strings.seo;
	const onChangeInput = (field: ContactField, value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		store.formShelf.field(field).onChange(value);
	};

	const onReload = () => window.location.reload();

	const onSubmit = async () => {
		await store.sendContactForFranchise();
		onReload();
	};

	return (
		<MainLayout contentContainerProps={{ position: "relative" }}>
			<NextSeo title={seoStrings.title} description={seoStrings.description} canonical={seoStrings.url} />
			<PageContainer>
				<Initial />
			</PageContainer>
			<AllMoments />
			<Highlights />
			<BusinessPlan />
			<Depoiment />
			<PageContainer>
				<FranchiseTable />
			</PageContainer>
			<ContactForm
				isLoading={store.loader.isLoading}
				onHandleChange={onChangeInput}
				onSubmit={onSubmit}
			/>
			<Box>
				<WhatsAppFloatingButton />
			</Box>
		</MainLayout>
	);
};

export default HomePage;
